import React from 'react'
import { NavLink } from 'react-router-dom'
import {
    // CalendarIcon,
    // ChartPieIcon,
    Cog6ToothIcon,
    // DocumentDuplicateIcon,
    // FolderIcon,
    HomeIcon,
    UsersIcon,
    PaintBrushIcon,
    TrophyIcon,
    SparklesIcon,
    CheckBadgeIcon,
    // CurrencyYenIcon
} from '@heroicons/react/24/outline'

import logo from '../assets/images/logo.png'

const navigation = [ 
    { name: '数据面板',   href: '/dash',      icon: HomeIcon, current: true },
    { name: '参赛作品',   href: '/entries',   icon: PaintBrushIcon, current: false },
    { name: '初评通过',   href: '/finalists', icon: SparklesIcon,   current: false },
    { name: '复评通过',   href: '/awards',    icon: CheckBadgeIcon, current: false },
    { name: '晋级国赛',   href: '/advances',  icon: TrophyIcon,     current: false },
]
const pages = [
    { name: '评委管理',   href: '/members',   icon: UsersIcon, current: false },
]

const navigationNew = [
    {
        id: 'entry',
        name: '',
        group: [
            { name: '数据面板',   href: '/dash',      icon: HomeIcon, current: true },
            { name: '参赛作品',   href: '/entries',   icon: PaintBrushIcon, current: false },
            { name: '初评通过',   href: '/finalists', icon: SparklesIcon,   current: false },
            { name: '复评通过',   href: '/awards',    icon: CheckBadgeIcon, current: false },
            { name: '晋级国赛',   href: '/advances',  icon: TrophyIcon,     current: false },
        ]
    },
    {
        id: 'member',
        name: '成员',
        group: [
            { name: '评委管理',   href: '/members',   icon: UsersIcon, current: false },
            { name: '院校管理',   href: '/institutions',   icon: UsersIcon, current: false },
        ]
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar({sidebarCollapse, setSidebarCollapse}) {
    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
                <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="Your Company"
                />
                <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : 'ml-2 text-white text-lg text-bold'}>好创意大赛</span>
            </div>

            <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                    { navigationNew.map((item) => (
                        <li key={item.id}>
                            { item.name && <div className="text-xs font-semibold leading-6 text-indigo-200">{ item.name }</div> }
                            <ul className="-mx-2 space-y-1">
                                { item.group.map((sub) => (
                                    <li key={sub.href}>
                                        <NavLink
                                            to={sub.href}
                                            className={({ isActive }) =>
                                                isActive ?
                                                "hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-indigo-700 text-white" :
                                                "hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-indigo-200"
                                            }
                                            title={sub.name}
                                        >
                                            <sub.icon
                                                className={classNames(
                                                    sub.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                                                    'h-6 w-6 shrink-0'
                                                )}
                                                aria-hidden="true"
                                                title={sub.name}
                                            />
                                            <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>{sub.name}</span>
                                        </NavLink>
                                    </li>
                                )) }
                            </ul>
                        </li>
                    )) }
                </ul>
            </nav>
        </div>
    )
}