export const UserReducer = (prevState = { profile: null }, action) => {
  let { type, payload } = action
  switch (type) {
    case 'set_profile':
      let newstate = { ...prevState }
      newstate.profile = payload
      return newstate
    default:
      return prevState
  }
}